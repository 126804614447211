<template>
  <ws-dialog
      v-model="display"
      :title="$t('file_browser.share_file.title')"
      width="700"
      max-height="600px"
      height="90%"
      not-centered
      no-padding

  >

    <template #header-after>
      <div class="d-flex">
        <ws-navigation-header
            @input="selectedUsers = []"
            v-model="navigation"
            :items="navigationSelect"
            no-number
            padding="24px"
        />
        <div :style="`border-bottom: 4px solid ${wsBACKGROUND}`" style="width : 100%" />
        <div :style="`border-bottom: 4px solid ${wsBACKGROUND}`">
          <ws-text-field
              v-model="search"
              :placeholder="$t('Search')"
              append-icon="mdi-magnify"
              class="pr-2"
              solo flat
              clearable
              width="200"
          />
        </div>

      </div>
    </template>

    <template #default>

      <!-- SHARED USERS -->
      <div v-if="navigation === 'shared_users'" class="fill-height">
        <!-- Items Table -->
        <ws-data-table
            v-if="items.length > 0"
            :items="items"
            :headers="headers"
            :search="search"
            :row-action="($event) => selectUser($event.uuid)"
            footer-portal="storage_file_access_footer"
        >

          <template #item.select="{item}">
            <v-btn @click.stop="selectUser(item.uuid)" min-width="24" width="24" height="24" :color="wsACCENT" text>
              <v-icon>{{ selectedUsers.includes(item.uuid) ?  'mdi-checkbox-marked-outline' :  'mdi-checkbox-blank-outline' }}</v-icon>
            </v-btn>
          </template>

          <template #item.name="{item}">
            <ws-user-data :item="item" />
          </template>

        </ws-data-table>
        <!-- No content-->
        <div v-if="items.length === 0" class="d-flex justify-center align-center fill-height">
          <div>
            <div class="d-flex justify-center">
              <v-icon size="80" :color="wsACCENT">
                mdi-account-tie
              </v-icon>
            </div>
            <h3 class="text-center">{{ $t('file_browser.share_file.not_shared') }}</h3>
            <h4 class="text-center font-weight-regular">{{ $t('file_browser.share_file.not_shared_text') }}</h4>
            <div class="d-flex justify-center mt-3">
              <ws-button @click="navigation='choose_users'" label="file_browser.share_file.choose_users" />
            </div>

          </div>

        </div>
      </div>
      <!-- CHOOSE USERS -->
      <!-- Managers -->
      <div v-if="navigation === 'choose_managers'" class="fill-height">
        <!-- Managers Select Table -->
        <ws-data-table
            v-if="managersSelectFiltered.length > 0"
            :items="managersSelectFiltered"
            :headers="managersHeaders"
            :search="search"
            :row-action="($event) => selectUser($event.uuid)"
            footer-portal="storage_file_access_footer"
        >
          <template #item.select="{item}">
            <v-btn @click.stop="selectUser(item.uuid)" min-width="24" width="24" height="24" :color="wsACCENT" text>
              <v-icon>{{ selectedUsers.includes(item.uuid) ?  'mdi-checkbox-marked-outline' :  'mdi-checkbox-blank-outline' }}</v-icon>
            </v-btn>
          </template>

          <template #item.name="{item}">
            <ws-user-data :item="item" />
          </template>

        </ws-data-table>
        <!-- No content-->
        <div v-if="managersSelectFiltered.length === 0" class="d-flex justify-center align-center fill-height">
          <div>
            <div class="d-flex justify-center">
              <v-icon size="80" :color="wsACCENT">
                mdi-account-tie
              </v-icon>
            </div>
            <h3 class="text-center">{{ $t('file_browser.share_file.no_managers') }}</h3>
            <h4 class="text-center font-weight-regular">{{ $t('file_browser.share_file.no_managers_text') }}</h4>

          </div>

        </div>
      </div>
      <!-- Users -->
      <div v-if="navigation === 'choose_students'" class="fill-height">
        <!-- Managers Select Table -->
        <ws-data-table
            v-if="clientsSelectFiltered.length > 0 && studentStorageEnabled"
            :items="clientsSelectFiltered"
            :headers="managersHeaders"
            :search="search"
            :row-action="($event) => selectUser($event.uuid, true)"
            footer-portal="storage_file_access_footer"
        >
          <template #item.select="{item}">
            <v-btn @click.stop="selectUser(item.uuid)" min-width="24" width="24" height="24" :color="wsACCENT" text>
              <v-icon>{{ selectedUsers.includes(item.uuid) ?  'mdi-checkbox-marked-outline' :  'mdi-checkbox-blank-outline' }}</v-icon>
            </v-btn>
          </template>

          <template #item.name="{item}">
            <ws-user-data :item="item" hide-contacts />
          </template>

        </ws-data-table>
        <!-- No content-->
        <div v-if="!studentStorageEnabled" class="d-flex justify-center align-center fill-height">
          <div>
            <div class="d-flex justify-center">
              <v-icon size="80" :color="wsACCENT">
                mdi-account-school-outline
              </v-icon>
            </div>
            <h3 class="text-center">{{ $t('file_browser.share_file.students.title') }}</h3>
            <h4 class="text-center font-weight-regular px-4 mt-2">{{ $t('file_browser.share_file.students.tooltip') }}</h4>
            <div class="d-flex justify-center">
              <ws-button
                  class="mt-4"
                  :to="businessDashLink('settings/storage')"
                  label="ToSettings"
              />
            </div>
          </div>

        </div>
      </div>
    </template>

    <template #footer>
      <portal-target name="storage_file_access_footer" />

      <div class="d-flex justify-space-between pa-6">
        <div>
          <ws-button
              @click="shareFile(true)"
              v-if="navigation==='shared_users' && items.length > 0"
              label="file_browser.unshare"
              :disabled="selectedUsers.length === 0"
              outlined
          />
        </div>
        <div>
          <ws-button
              @click="shareFile"
              v-if="(navigation==='choose_managers' && managersSelectFiltered.length > 0) || (navigation==='choose_students' && clientsSelectFiltered.length > 0)"
              label="file_browser.share"
              :disabled="selectedUsers.length === 0"
          />
        </div>

      </div>

    </template>

  </ws-dialog>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "fileAccessDialog",
  props : {
    value : {
      type : Boolean,
      default : false
    },
    item : {
      type : Object ,
      default() { return {} }
    }
  },
  data() {
    return {
      items : [],
      managersSelect : [],
      clientsSelect : [],
      display : false,
      navigation : 'shared_users',
      selectedUsers : [],
      search : ''
    }
  },
  computed : {
    studentStorageEnabled() {
      return this.$store.state.business.selectedBusiness.enable_student_storage
    },
    managersSelectFiltered() {
      let items = this.COPY(this.managersSelect)
      items = items.filter(el => !this.items.map(el => el.uuid).includes(el.uuid))
      return items
    },
    clientsSelectFiltered() {
      let items = this.COPY(this.clientsSelect)
      items = items.filter(el => !this.items.map(el => el.uuid).includes(el.uuid))
      return items
    },

    managersHeaders() {
      //
      let items =  [
        { value : 'select' , width : 20 },
        { text : this.$t('HumanName') , value : 'name' },
        { text : this.$t('Phone')     , value : 'phone'},
        { value : 'email' , align : 'd-none' },
      ]

      if (!(this.IS_BUSINESS_OWNER || !this.MODULE_PERMISSIONS(['MODULE_WESTUDY__HIDE_STUDENT_CONTACTS'], true))) {
        items = items.filter(el=> el.value !== 'phone')
      }

      return items
    },
    headers() {
      return [
        { value : 'select' , width : 20 },
        { text : this.$t('HumanName') , value : 'name' },
      ]
    },

    navigationSelect() {
      return [
        { text : `${this.$t('file_browser.share_file.shared_users')} ${this.items.length > 0 ? `(${this.items.length})` : '' }` , value : 'shared_users' },
        { text : `${this.$t('Managers')} ${this.managersSelectFiltered.length > 0 ? `(${this.managersSelectFiltered.length})` : ''}` , value : 'choose_managers' },
        { text : `${this.$t('Students')} ${this.clientsSelectFiltered.length > 0 ? `(${this.clientsSelectFiltered.length})` : ''}` , value : 'choose_students' }
      ]
    }
  },
  watch : {
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    },
    display() {
      if ( this.display !== this.value ) {
        this.$emit('input' , this.display)
      }
    }
  },
  methods : {
    ...mapActions('file_browser', [
      'GET_FILE_SHARED_USERS' ,
      'EDIT_FILE_SHARED_USERS' ,
    ]),
    ...mapActions('crmSystem', [
      'GET_BUSINESS_CLIENTS_SELECT' ,
    ]),

    async shareFile(remove = false) {
      let data = {
        uuid : this.item.uuid,
        is_folder : this.item.is_folder,
        users : this.selectedUsers,
        remove : remove
      }
      let result = await this.EDIT_FILE_SHARED_USERS(data)
      if ( !result ) {
        return this.notify(this.$t('Error'))
      }
      if ( ['choose_managers' , 'choose_students'].includes(this.navigation)) {
        this.items.push(...result.users)
        if (this.navigation === 'choose_managers') {
          this.managersSelect = this.managersSelect.filter( el => !this.selectedUsers.includes(el.uuid))
        } else {
          this.clientsSelect = this.clientsSelect.filter( el => !this.selectedUsers.includes(el.uuid))
        }

        this.$emit('update-shared-users' , result.users.length)
      } else {
        this.items = this.items.filter( el => !this.selectedUsers.includes(el.uuid))
        this.$emit('update-shared-users' , -result.users.length)
        result.users.forEach((user ) => {
          let userManagerCheck = this.managersSelect.find(el => el.uuid === user.uuid)
          let userClientsCheck = this.clientsSelect.find(el => el.uuid === user.uuid)
          if ( !userManagerCheck ) {
            this.managersSelect.push(user)
          }
          if ( !userClientsCheck ) {
            this.clientsSelect.push(user)
          }
        })
      }

      this.selectedUsers = []

    },

    selectUser(uuid) {
      if ( this.selectedUsers.includes(uuid) ) {
        this.selectedUsers = this.selectedUsers.filter(el => el !== uuid)
      } else {
        this.selectedUsers.push(uuid)
      }
    },

    async getClientsSelect() {
      if (!this.studentStorageEnabled) {
        return
      }
      let result = await this.GET_BUSINESS_CLIENTS_SELECT()
      if ( !result || result === true ) {
        return
      }
      this.clientsSelect = result
    },
    async initPage() {

      let data = {
        is_folder : this.item.is_folder,
        uuid : this.item.uuid
      }
      let result = await this.GET_FILE_SHARED_USERS(data)
      if ( !result ) {
        return this.$t('NetworkError')
      }

      this.items = result.shared_users
      this.managersSelect = result.managers_select



    }
  },
  mounted() {
    if ( this.value !== this.display) {
      this.display = this.value
    }
    this.initPage()
    this.getClientsSelect()
  }
}
</script>

<style scoped>

</style>